<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <h1>士高60期丁班測驗系統</h1>
      <Loader /><!-- 資料加載 -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link class="nav-link" to="/" @click="logout" v-if="this.$router.currentRoute.value.path !== '/'">登入</router-link>
          <router-link class="nav-link" to="/examLobby" @click="toggle" v-if="this.$store.state.account.ident !== ''">測驗大廳</router-link>
          <router-link class="nav-link" to="/systemLog" @click="toggle">系統使用狀態</router-link>
          <router-link class="nav-link" to="/discussion" @click="toggle">我心底的話</router-link>
          <router-link class="nav-link" to="/profile" @click="toggle" v-if="this.$store.state.account.ident === 'User'">個人專區</router-link>
          <router-link class="nav-link" to="/weakAnalysis" @click="toggle" v-if="this.$store.state.account.ident === 'User'">弱點分析</router-link>
          <router-link class="nav-link" to="/systemManage" @click="toggle" v-if="this.$store.state.account.ident === 'Admin'">系統管理</router-link>
          <router-link class="nav-link" to="/about" @click="toggle">關於系統</router-link>
          <button class="btn btn-outline-success me-2" type="button" @click="logout" v-if="this.$store.state.account.ident !== ''">系統登出</button>
          <p class="mt-3 mb-0 text-center" v-if="this.$store.state.account.ident !== ''">{{ userName }} 同學~您好！</p>
          <hr class="mt-0" />
        </div>
      </div>
    </div>
  </nav>
  <router-view />
  <Footer />
</template>

<script>
import Loader from "@/components/Loading.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Loader,
    Footer,
  },
  data() {
    return {
      userName: "",
    };
  },
  methods: {
    toggle() {
      document.querySelector(".navbar-collapse").classList.remove("show");
    },
    logout() {
      this.$store.commit("SET_ACCOUNT", {
        studentID: "",
        name: "",
        ident: "",
        examExp: {},
      });
      // 清除token值
      localStorage.removeItem("eleToken");
      document.querySelector(".navbar-collapse").classList.remove("show");
      this.$router.push("/");
    },
  },
  mounted() {},
  beforeUpdate() {
    this.userName = this.$store.state.account.name;
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Noto Sans TC", sans-serif;
  background-color: #fff8dc;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: #000;
}
</style>

<!-- Footer -->
<template>
  <footer>
    <div class="card">
      <div class="card-body">
        <h6 class="card-title text-center">資訊專業，求實創新，與時俱進</h6>
        <p class="card-text text-center">
          CopyRight ©
          {{ new Date().getFullYear() }}
          <strong @click="$router.push('/about')">趙德柱 WebDev</strong>
          版權所有.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss">
footer {
  margin-top: auto;
}
</style>

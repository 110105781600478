<!-- 加載器 -->
<template>
  <div class="text-center loading" v-show="$store.state.loader">
    <div class="spinner-grow text-info" style="width: 5rem; height: 5rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-warning" style="width: 5rem; height: 5rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p>正在加載中...</p>
    <div class="spinner-grow text-danger" style="width: 5rem; height: 5rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: ":Loading",
  /*
使用方法

<Loader /><!-- 資料加載 -->

import Loader from "@/components/Loading.vue";

components: {
    Loader,
},

*/
};
</script>

<style lang="scss">
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    backdrop-filter: blur(20px);
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/discussion",
    name: "Discussion",
    component: () => import("@/views/Discussion.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/systemLog",
    name: "SystemLog",
    component: () => import("@/views/SystemLog.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/denied",
    name: "Denied",
    component: () => import("@/views/Denied.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/404.vue"),
    meta: { auth: "Everyone" },
  },
  {
    path: "/examLobby",
    name: "ExamLobby",
    component: () => import("@/views/ExamLobby.vue"),
    meta: { auth: "User" },
  },
  {
    path: "/exam",
    name: "Exam",
    component: () => import("@/views/Exam.vue"),
    meta: { auth: "User" },
  },
  {
    path: "/examResult",
    name: "ExamResult",
    component: () => import("@/views/ExamResult.vue"),
    meta: { auth: "User" },
  },
  {
    path: "/weakAnalysis",
    name: "WeakAnalysis",
    component: () => import("@/views/WeakAnalysis.vue"),
    meta: { auth: "User" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: { auth: "User" },
  },
  {
    path: "/systemManage",
    name: "SystemManage",
    component: () => import("@/views/SystemManage.vue"),
    meta: { auth: "Admin" },
  },
  {
    path: "/accountManage",
    name: "AccountManage",
    component: () => import("@/views/AccountManage.vue"),
    meta: { auth: "Admin" },
  },
  {
    path: "/accountEdit",
    name: "AccountEdit",
    component: () => import("@/views/AccountEdit.vue"),
    meta: { auth: "Admin" },
  },
  {
    path: "/questionManage",
    name: "QuestionManage",
    component: () => import("@/views/QuestionManage.vue"),
    meta: { auth: "Admin" },
  },
  {
    path: "/questionEdit",
    name: "QuestionEdit",
    component: () => import("@/views/QuestionEdit.vue"),
    meta: { auth: "Admin" },
  },
  {
    path: "/countDownEdit",
    name: "CountDownEdit",
    component: () => import("@/views/CountDownEdit.vue"),
    meta: { auth: "Admin" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 跳轉頁面後回到最頂端
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// 路由守衛
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.eleToken ? true : false;
  if (to.meta.auth === "Everyone") next(); // 匿名訪客會到的地方
  else {
    // 需要登入才可進入的地方，涵蓋使用者與管理者
    if (to.meta.auth === "User") {
      // 使用者頁面驗證
      isLogin ? next() : next("/");
    } else {
      // 管理者才能去的地方
      if (store.state.account.ident === "Admin") isLogin ? next() : next("/");
      else isLogin ? next("/denied") : next("/");
    }
  }
});

export default router;

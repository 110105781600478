import axios from "axios";
import store from "./store";
import router from "./router";

// 請求攔截
axios.interceptors.request.use(
  (config) => {
    // 加載動畫
    store.commit("LOADER", true);
    if (localStorage.eleToken) {
      // 設置統一的請求表頭
      config.headers.Authorization = localStorage.eleToken;
    }
    return config;
  },
  (error) => {
    store.commit("LOADER", false);
    return Promise.reject(error);
  }
);

// 響應攔截 401 token過期處理
axios.interceptors.response.use(
  (response) => {
    // 隱藏動畫
    store.commit("LOADER", false);
    return response;
  },
  (error) => {
    store.commit("LOADER", false);
    // 獲取錯誤狀態碼
    const { status } = error.response;
    if (status === 401) {
      console.log("token值失效，請重新登入！");
      // 清除token值
      localStorage.removeItem("eleToken");
      // 跳轉到登入頁面

      router.push("/");
    }
    return Promise.reject(error);
  }
);

// 設定主機前綴(避免巢狀路由產生問題)
const url = window.location.origin;
axios.defaults.baseURL = url;

export default axios;

import { createStore } from "vuex";

export default createStore({
  state: {
    alertVisible: false, // 訊息提示框顯示
    loader: false, // 加載動畫控制
    countDown: "", // Login.vue 倒數計時儲存
    // 帳戶資料
    account: {
      studentID: "",
      name: "",
      ident: "",
      examExp: {},
    },
    examination: {}, // 處理-考試的相關資料傳送
    accountEdit: {}, // 處理-題組編輯相關資料傳送
    questGroupEdit: {}, // 處理-題組編輯相關資料傳送
  },
  getters: {},
  mutations: {
    SET_ALERT_VISIBLE(state, value) {
      state.alertVisible = value;
    },
    LOADER(state, payload) {
      state.loader = payload;
    },
    SET_COUNT_DOWN(state, value) {
      state.countDown = value;
    },
    SET_ACCOUNT(state, value) {
      state.account = value;
    },
    SET_EXAMINATION(state, payload) {
      state.examination = payload;
    },
    SET_ACCOUNT_EDIT(state, payload) {
      state.accountEdit = payload;
    },
    SET_QUEST_GROUP_EDIT(state, payload) {
      state.questGroupEdit = payload;
    },
  },
  actions: {},
  modules: {},
});
